import React from 'react'
import { Col, Row, Typography } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import s from './Contacts.module.less'
import links from '../../helpers/links'


const { Text, Paragraph } = Typography

const ContactsComponent = ({ viewport }) => {

  const isMobile = isMobileLayout(viewport)

  const history = useHistory()

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button type="icon" icon="left" isSmall handleClick={() => history.goBack()} />
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <WidthContainer>
        {
          !isMobile && (
            <Box className={s.buttonBack}>
              <Button type="icon" icon="left" handleClick={() => history.goBack()} />
            </Box>
          )
        }

        <Box className={s.container}>
          <Box mt={isMobile ? 'lg' : 'xlg'} mb="md">
            <Row className={s.title}>
              <Text>Контакты</Text>
            </Row>
            <Row className={s.innerTitle}>
              <Text>
                Телефон для заказа:
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerText}>
              <Text>
                <span style={isMobile
                  ? { fontWeight: 'bold', fontSize: '22px' }
                  : { fontWeight: 'bold', fontSize: '21px' }}
                >
                  <Paragraph
                    style={{ cursor: 'pointer', marginBottom: -20 }}
                    onClick={() => {
                      document.location.href = 'tel:+74959601242'
                    }}>+7 (495) 960-12-42
                  </Paragraph>
                </span> <br />
                С 10:00 до 23:00 <br />
                Быстрая доставка по Москве и Московской области
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Обратная связь:
              </Text>
            </Row>
            <Row className={s.innerText}>
              <Text>
                Пожелания и замечания можно отправить в службу поддержки и контроля качества <br />
                через форму на сайте <NavLink to={links.support} style={{ color: '#2B5E51', cursor: 'pointer' }}>«Связаться с нами»</NavLink>  или на почту <br />
                <span style={isMobile
                  ? { fontWeight: 'bold', fontSize: '20px' }
                  : { fontWeight: 'bold' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'mailto:hello@eatstreet.ru'
                    }}>hello@eatstreet.ru
                  </Paragraph>
                </span>
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Вакансии и вопросы по работе у нас:
              </Text>
            </Row>
            <Row className={s.innerText}>
              <Text>
                Почта отдела кадров:
                <span style={isMobile
                  ? { fontWeight: 'bold', display: 'flex', flexDirection: 'column', fontSize: '22px' }
                  : { fontWeight: 'bold', fontSize: '18px' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'mailto:hr@eatstreet.ru'
                    }}>hr@eatstreet.ru
                  </Paragraph>
                </span><br />
                Телефон отдела кадров:
                <span style={isMobile
                  ? { fontWeight: 'bold', display: 'flex', flexDirection: 'column', fontSize: '22px' }
                  : { fontWeight: 'bold', fontSize: '18px' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'tel:+79031258499'
                    }}>+7 (903) 125-84-99
                  </Paragraph>
                </span>
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Корпоративным клиентам:
              </Text>
            </Row>
            <Row className={s.innerText}>
              <Text>
                Для заключения договоров на корпоративное питание или поставки <br />
                продукции отправьте запрос на почту <br />
                <span style={isMobile
                  ? { fontWeight: 'bold', fontSize: '20px' }
                  : { fontWeight: 'bold' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'mailto:b2b@eatstreet.ru'
                    }}>b2b@eatstreet.ru
                  </Paragraph>
                </span>
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Предложения по рекламе:
              </Text>
            </Row>
            <Row className={s.innerText}>
              <Text>
                Ваши идеи и предложения по рекламе/ кросс-промо присылайте на почту <br />
                <span style={isMobile
                  ? { fontWeight: 'bold', fontSize: '20px' }
                  : { fontWeight: 'bold' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'mailto:marketing@eatstreet.ru'
                    }}>marketing@eatstreet.ru
                  </Paragraph>
                </span>
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Поставщикам
              </Text>
            </Row>
            <Row className={s.innerText}>
              <Text>
                Ждём ваши предложения на почту <br />
                <span style={isMobile
                  ? { fontWeight: 'bold', fontSize: '20px' }
                  : { fontWeight: 'bold' }}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      document.location.href = 'mailto:supply@eatstreet.ru'
                    }}>supply@eatstreet.ru
                  </Paragraph>
                </span>
              </Text>
            </Row>
          </Box>
          <Box>
            <Row className={s.innerTitle}>
              <Text>
                Реквизиты
              </Text>
            </Row>
            <Row className={s.innerRequisitesText}>
              <Text>
                ООО «MK» <br />
                ИНН: 7716948906 <br />
                КПП: 771401001 <br />
                ОГРН: 1207700288630 <br />
                Р/С 40702810601600011495<br />
                АО &quot;АЛЬФА-БАНК&quot;<br />
                БИК 044525593<br />
                К/С 30101810200000000593<br />
                Юр. адрес: 125252, г. Москва,<br />ул. Авиаконструктора Микояна, д. 12, помещ. 4/2
              </Text>
            </Row>
          </Box>

        </Box>
      </WidthContainer>
    </Box>
  )
}

const Contacts = compose(
  withViewport,
)(ContactsComponent)

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(Contacts)